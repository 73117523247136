import React from 'react';
import { Image, Link } from "rebass";
import { Box } from "reflexbox"
import { Heading1, Subheading, Heading2, Paragraph,
  Container, Section, Footer } from "./Components";

function Home() {
  return (
    <>
      <Section>
        <Container pt="10vh">
          <Heading2>under construction</Heading2>
        </Container>
      </Section>
    </>
  );
}

export default Home;
